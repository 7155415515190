import { useEffect, useState, useCallback } from 'react'
import { Auth } from 'aws-amplify'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { Form, Input, Button, Alert, Typography } from 'antd'

const { Title } = Typography

export default withAuthenticator(function Profile () {
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    updateUser()
  }, [])

  const updateUser = async () => {
    const user = await Auth.currentAuthenticatedUser()
    setUser(user)
  }

  const handlePasswordChange = useCallback(async ({ firstName, oldPassword, newPassword }) => {
    await setLoading(true)
    await setPasswordChanged(false)
    await setError(null)

    try {
      await Auth.changePassword(user, oldPassword, newPassword)
      await setPasswordChanged(true)
    } catch (err) {
      await setError(err.message)
    }
    await setLoading(false)
  }, [user])

  return (
    <>
      <Title>Profile for {user?.username}</Title>
      <Form
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={handlePasswordChange}
      >
        {passwordChanged && <Alert message='Password changed!' type='success' />}
        {!!error && <Alert message={error} type='error' />}
        <Form.Item
          label='Old Password'
          name='oldPassword'
          rules={[{ required: true, message: 'Please input your old password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label='New Password'
          name='newPassword'
          rules={[{ required: true, message: 'Please input your new password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type='primary' htmlType='submit' loading={loading}>
            set new password
          </Button>
        </Form.Item>

      </Form>
    </>
  )
})
