import { useState } from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { Button, Typography } from 'antd'
import AddWallet from './Add'
import List from './List'

const { Title } = Typography

function Wallets () {
  const [displayAdd, setDisplayAdd] = useState(false)

  const showAdd = () => setDisplayAdd(true)
  const hideAdd = () => setDisplayAdd(false)

  return (
    <>
      <Title>Wallets</Title>

      {!displayAdd && <Button onClick={showAdd} type='primary' style={{ marginBottom: 16 }}>Add a Wallet</Button>}
      {displayAdd && <AddWallet onCreate={hideAdd} onCancel={hideAdd} />}
      <List />
    </>
  )
}

export default withAuthenticator(Wallets)
