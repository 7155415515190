import { useEffect, useState, useCallback } from 'react'
import { Auth } from 'aws-amplify'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { Spin, Form, Button, Alert, Typography, Radio } from 'antd'

const { Title } = Typography

export default withAuthenticator(function Settings () {
  const [dataChanged, setDataChanged] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currency, setCurrency] = useState('usd')
  const [user, setUser] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    updateUser()
  }, [])

  const updateUser = async () => {
    const user = await Auth.currentAuthenticatedUser()
    setCurrency(String(user.attributes['custom:currency']))
    setUser(user)
  }

  const handleSettingsChange = useCallback(async ({ currency }) => {
    await setLoading(true)
    await setDataChanged(false)
    await setError(null)

    try {
      await Auth.updateUserAttributes(user, { 'custom:currency': currency })
      await setDataChanged(true)
    } catch (err) {
      await setError(err.message)
    }
    await setLoading(false)
  }, [user])

  if (!user) {
    return <Spin />
  }

  return (
    <>
      <Title>Settings for {user?.username}</Title>
      <Form
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={handleSettingsChange}
        initialValues={{ currency }}
      >
        {dataChanged && <Alert message='Settings updated!' type='success' />}
        {!!error && <Alert message={error} type='error' />}
        <Form.Item label='Currency' name='currency'>
          <Radio.Group>
            <Radio value='usd'>USD</Radio>
            <Radio value='eur'>EUR</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type='primary' htmlType='submit' loading={loading}>
            update settings
          </Button>
        </Form.Item>

      </Form>
    </>
  )
})
