/* src/App.js */
import './App.css'
import React, { useState, useEffect } from 'react'
import { Layout } from 'antd'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'

import Routing from './Navigation/Routing'
import MainMenu from './Navigation/Menu'

const { Header, Content, Footer, Sider } = Layout

export default function App () {
  const [authState, setAuthState] = useState()
  const [user, setUser] = useState()
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState)
      setUser(authData)
    })
  }, [])

  useEffect(() => {
    setLoggedIn(authState === AuthState.SignedIn && !!user)
  }, [user, authState])

  return (
    <Layout>
      <Sider breakpoint='lg' collapsedWidth='0'>
        <div className='logo' />
        <MainMenu loggedIn={loggedIn} />
      </Sider>
      <Layout>
        <Header className='site-layout-sub-header-background' style={{ padding: 0 }} />
        <Content style={{ margin: '24px 16px 0' }}>
          <div className='site-layout-background' style={{ padding: 24, minHeight: 360 }}>
            <Routing loggedIn={loggedIn} />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>ChainTracker</Footer>
      </Layout>
    </Layout>
  )
}
