import { useState, useEffect, useCallback } from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { API, graphqlOperation, Auth } from 'aws-amplify'
import { getWallet } from '../graphql/queries'
import { updateWallet, deleteWallet } from '../graphql/mutations'
import { onCreateWallet, onDeleteWallet } from '../graphql/subscriptions'

import { Table } from 'antd'

const { Column } = Table

const listWalletsQl = `
  query listWallets ($owner: String) {
    listWallets(filter: {isDeleted: {eq: false}, owner: {eq: $owner}}) {
      items {
        balances {
          items {
            id
            token
            balance
            updatedAt
          }
        }
        id
        owner
        name
        network
        address
      }
    }
  }
`

function WalletList () {
  const [wallets, setWallets] = useState([])
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    updateUser()
  }, [])

  const updateUser = async () => {
    const user = await Auth.currentAuthenticatedUser()
    setUser(user)
  }

  const handleOnAddWallet = useCallback(async (wallet) => {
    if (wallet.owner === user.attributes.sub) {
      fetchWallets()
    }
  }, [user])

  const handleOnDeleteWallet = useCallback(async (wallet) => {
    if (wallet.owner === user.attributes.sub) {
      fetchWallets()
    }
  }, [user])

  useEffect(() => {
    const walletCreateSubscription = API.graphql(
      graphqlOperation(onCreateWallet)
    ).subscribe({
      next: ({ value }) => handleOnAddWallet(value.data.onCreateWallet)
    })

    const walletDeleteSubscription = API.graphql(
      graphqlOperation(onDeleteWallet)
    ).subscribe({
      next: ({ value }) => handleOnAddWallet(value.data.onDeleteWallet)
    })

    return () => {
      walletCreateSubscription.unsubscribe()
      walletDeleteSubscription.unsubscribe()
    }
  }, [handleOnAddWallet, handleOnDeleteWallet])

  useEffect(() => {
    fetchWallets()
  }, [])

  async function fetchWallets () {
    const user = await Auth.currentAuthenticatedUser()
    const rawWallets = await API.graphql(graphqlOperation(listWalletsQl, { owner: user.attributes.sub }))
    await setWallets(rawWallets?.data?.listWallets?.items || [])
    await setLoading(false)
  }

  const handleDelete = ({ id }) => async () => {
    const currentData = await API.graphql(graphqlOperation(getWallet, { id }))
    const { _version } = currentData.data.getWallet

    const updatedData = await API.graphql(graphqlOperation(updateWallet, { input: { id, _version, isDeleted: true } }))
    await API.graphql(graphqlOperation(deleteWallet, { input: { id, _version: updatedData.data.updateWallet._version } }))
  }

  return (
    <>
      <Table dataSource={wallets} loading={loading} rowKey='id'>
        <Column ellipsis title='Network' dataIndex='network' key='network' />
        <Column responsive={['lg']} title='Address' dataIndex='address' key='address' />
        <Column ellipsis title='Name' dataIndex='name' key='name' />
        <Column ellipsis title='Action' key='action' render={(text, record) => <button onClick={handleDelete(record)}>Delete</button>} />
      </Table>
    </>
  )
}

export default withAuthenticator(WalletList)
