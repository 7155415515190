import { Menu } from 'antd'
import { HomeOutlined as IconHome, UserOutlined as IconUser, WalletOutlined as IconWallet } from '@ant-design/icons'
import { Link } from 'wouter'
import { Auth } from 'aws-amplify'

export default function NavigationMenu ({ loggedIn }) {
  async function handleSignOut () {
    try {
      await Auth.signOut()
    } catch (error) {
      console.log('error signing out: ', error)
    }
  }

  return (
    <Menu theme='dark' mode='inline' defaultSelectedKeys={['4']}>
      <Menu.Item key='public' icon={<IconHome />}>
        <Link href='/'>Home</Link>
      </Menu.Item>
      {loggedIn && (<Menu.Item key='wallest' icon={<IconWallet />}><Link href='/wallets'>Wallets</Link></Menu.Item>)}
      {loggedIn && (<Menu.Item key='settings' icon={<IconUser />}><Link href='/settings'>Settings</Link></Menu.Item>)}
      {loggedIn && (<Menu.Item key='profile' icon={<IconUser />}><Link href='/me'>Profile</Link></Menu.Item>)}
      {loggedIn && (<Menu.Item key='logout' icon={<IconUser />}><Link href='/' onClick={handleSignOut}>Sign Out</Link></Menu.Item>)}
    </Menu>

  )
}
