import { useState, useEffect } from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { API } from 'aws-amplify'
import { Statistic, Spin } from 'antd'

export default withAuthenticator(function MarketValue ({ token }) {
  const [market, setMarket] = useState()

  useEffect(() => {
    fetchMarket(token)
  }, [token])

  async function fetchMarket (token) {
    const market = await API.get('markets', `/markets/${token}`)
    await setMarket(market)
  }

  if (!market) {
    return <Spin />
  }

  return (
    <Statistic
      title={market.name}
      value={market.market_data.current_price.usd}
      precision={market.market_data.current_price.usd < 10 ? 4 : 0}
      suffix='USD'
    />
  )
})
