import { useState, useEffect } from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { API, graphqlOperation, Auth } from 'aws-amplify'
import { onUpdateBalance } from '../graphql/subscriptions'
import { Table } from 'antd'

const { Column } = Table

const listWalletsQl = `
  query listWallets ($owner: String) {
    listWallets(filter: {isDeleted: {eq: false}, owner: {eq: $owner}}) {
      items {
        balances {
          items {
            id
            token
            balance
            updatedAt
          }
        }
        owner
        network
        address
      }
    }
  }
`

export default withAuthenticator(function Tokens () {
  const [wallets, setWallets] = useState([])
  const [balances, setBalances] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const balanceUpdateSubscription = API.graphql(
      graphqlOperation(onUpdateBalance)
    ).subscribe({
      next: fetchWallets
    })

    return () => {
      balanceUpdateSubscription.unsubscribe()
    }
  }, [])

  useEffect(() => {
    fetchWallets()
  }, [])

  useEffect(() => {
    const balances = []
    wallets.forEach(wallet => {
      balances.push(...wallet.balances.items)
    })
    setBalances(balances)
  }, [wallets])

  async function fetchWallets () {
    const user = await Auth.currentAuthenticatedUser()
    const rawWallets = await API.graphql(graphqlOperation(listWalletsQl, { owner: user.attributes.sub }))
    await setWallets(rawWallets?.data?.listWallets?.items || [])
    await setLoading(false)
  }

  return (
    <Table dataSource={balances} loading={loading} rowKey='id'>
      <Column title='Balance' align='right' dataIndex='balance' key='balance' render={balance => new Intl.NumberFormat(navigator.language, { maximumFractionDigits: 0 }).format(balance)} />
      <Column title='Token' dataIndex='token' key='token' />
    </Table>
  )
})
