import { Switch, Route } from 'wouter'

import Home from '../Home'
import Dashboard from '../Dashboard'
import UserProfile from '../User/Profile'
import UserSettings from '../User/Settings'
import Wallets from '../Wallets'

export default function NavigationRouting ({ loggedIn }) {
  return (
    <Switch>
      {!loggedIn && <Route path='/' component={Home} />}
      {loggedIn && <Route path='/' component={Dashboard} />}
      <Route path='/settings' component={UserSettings} />
      <Route path='/me' component={UserProfile} />
      <Route path='/wallets' component={Wallets} />
    </Switch>
  )
}
