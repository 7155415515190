/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:51d7845f-c719-456d-b79a-491f1714d6a6",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_fWoqZOFuX",
    "aws_user_pools_web_client_id": "6oo38md4kefebd555juk7c1ceh",
    "oauth": {},
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_appsync_graphqlEndpoint": "https://ph4j7x7ytvatbcajuoxfz3wy34.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-hpjgt5plkfbhnfmyraqpuisr74",
    "aws_cloud_logic_custom": [
        {
            "name": "markets",
            "endpoint": "https://fdnn1ctrv7.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
