import { withAuthenticator } from '@aws-amplify/ui-react'
import { Typography, Card, Row, Col } from 'antd'
import Tokens from './Tokens'
import MarketValue from './MarketValue'
import PortfolioValue from './PortfolioValue'

const { Title } = Typography

export default withAuthenticator(function Dashboard () {
  return (
    <>
      <Title>Dashboard</Title>

      <Row gutter={[24, 24]}>
        <Col xs={24} md={6}>
          <Card>
            <MarketValue token='bitcoin' />
          </Card>
        </Col>
        <Col xs={24} md={6}>
          <Card>
            <MarketValue token='ethereum' />
          </Card>
        </Col>
        <Col xs={24} md={6}>
          <Card>
            <MarketValue token='vechain' />
          </Card>
        </Col>
        <Col xs={24} md={6}>
          <Card>
            <PortfolioValue />
          </Card>
        </Col>

        <Col xs={24} md={12}>
          <Tokens />
        </Col>
      </Row>
    </>
  )
})
