import { useState } from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { API, graphqlOperation, Auth } from 'aws-amplify'
import { createWallet } from '../graphql/mutations'
import { Card, Form, Input, Select, Button, Alert } from 'antd'

const { Option } = Select

function AddWallet ({ onCreate = () => { }, onCancel = () => {} }) {
  const [error, setError] = useState()

  async function handleAddWallet ({ name, network, address }) {
    await setError()
    try {
      const user = await Auth.currentAuthenticatedUser()
      const owner = user.attributes.sub
      await API.graphql(graphqlOperation(createWallet, { input: { owner, name, network, address, isDeleted: false } }))
      await onCreate()
    } catch (err) {
      await setError(err.message)
    }
  }

  return (
    <Card title='Add new Wallet' extra={<Button onClick={onCancel}>X</Button>}>
      {!!error && <Alert message={error} type='error' />}
      <Form
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={handleAddWallet}
      >
        <Form.Item label='Wallet Name'>
          <Form.Item name='name' noStyle><Input /></Form.Item>
        </Form.Item>
        <Form.Item label='Address'>
          <Form.Item name='address' noStyle><Input /></Form.Item>
        </Form.Item>
        <Form.Item label='Network'>
          <Form.Item name='network' noStyle>
            <Select>
              <Option value='bitcoin'>Bitcoin</Option>
              <Option value='vechain'>VeChain</Option>
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type='primary' htmlType='submit'>
            Add new Wallet
          </Button>
        </Form.Item>
      </Form>
    </Card>

  )
}

export default withAuthenticator(AddWallet)
