import { useState, useEffect } from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { API, graphqlOperation, Auth } from 'aws-amplify'
import { onUpdateBalance } from '../graphql/subscriptions'
import { Statistic, Spin } from 'antd'

const listWalletsQl = `
  query listWallets ($owner: String) {
    listWallets(filter: {isDeleted: {eq: false}, owner: {eq: $owner}}) {
      items {
        balances {
          items {
            id
            token
            balance
            updatedAt
          }
        }
        owner
        network
        address
      }
    }
  }
`

export default withAuthenticator(function PortfolioValue () {
  const [wallets, setWallets] = useState([])
  const [loading, setLoading] = useState(true)
  const [markets, setMarkets] = useState()
  const [value, setValue] = useState(null)

  useEffect(() => {
    const balanceUpdateSubscription = API.graphql(
      graphqlOperation(onUpdateBalance)
    ).subscribe({
      next: fetchData
    })

    return () => {
      balanceUpdateSubscription.unsubscribe()
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    let value = 0
    if (markets && wallets) {
      wallets.forEach(wallet => {
        wallet.balances.items.forEach(({ token, balance }) => {
          value += balance * (markets[token] || 0)
        })
      })
    }
    setValue(value)
  }, [markets, wallets])

  async function fetchData () {
    const markets = await API.get('markets', '/markets')
    await setMarkets(markets)

    const user = await Auth.currentAuthenticatedUser()
    const rawWallets = await API.graphql(graphqlOperation(listWalletsQl, { owner: user.attributes.sub }))
    await setWallets(rawWallets?.data?.listWallets?.items || [])
    await setLoading(false)
  }

  if (loading) {
    return <Spin />
  }

  return (
    <Statistic
      title='Portfolio'
      value={value}
      precision={0}
      suffix='USD'
    />
  )
})
